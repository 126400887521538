import { Component, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import {
  DEFAULT_TABLE_OPTIONS,
  SORT_ORDER_DESC,
  STATUS_ENABLE,
  TableOptions
} from "@/api/request";
import { SearchRequest } from "@/api/account/request";
import AccountSearch, { ListItem } from "@/store/account/search";
import Flash, { ErrorAlert, SuccessAlert } from "@/store/common/flash";
import AccountModify from "@/store/account/modify";
import { DeleteRequest } from "@/api/coupon/request";

@Component({
  components: { UlContentHeader, UlBreadcrumbs, UIDataTable, UIDialogDelete }
})
export default class Index extends Vue {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "店舗アカウント設定";
  headingSub = "Login members setting";
  breadCrumbs = [
    { text: "設定", disabled: true },
    { text: "店舗アカウント設定一覧", disabled: true }
  ];

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "ログインID", value: "loginId" },
    { text: "管理者名", value: "name" },
    { text: "メールアドレス", value: "email", width: "103" },
    { text: "アカウント有効期限", value: "expireDate", width: "103" },
    {
      text: "パスワード有効期限",
      value: "expirationPasswordDate",
      width: "103"
    },
    {
      text: "状態",
      value: "status",
      sortable: false
    },
    {
      label: "削除",
      text: "",
      value: "delete",
      sortable: false
    },
    {
      label: "修正",
      text: "",
      value: "edit",
      sortable: false
    },
    {
      label: "権限設定",
      text: "",
      value: "action",
      sortable: false
    }
  ];
  // ------------

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  // 削除ダイアログの表示有無
  showDialog = false;

  // 削除されるタイトル
  deletingTitle = "";

  // 削除されるメッセージID
  deletingId = 0 as number;

  isLoading = false;

  // 検索入力オプション
  inputOptions = {
    loginId: null as string | null,
    name: null as string | null,
    status: 0 as number | 0
  };

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = {
    page: 1,
    displayCount: 20,
    sortKey: "login_id",
    sortOrder: SORT_ORDER_DESC
  };

  // ------------

  /**
   * 新規作成権限の有無
   */
  get writeFlg() {
    return AccountSearch.getWriteFlg;
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return AccountSearch.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return AccountSearch.getTotalCount;
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await AccountSearch.restore(this.inputOptions as SearchRequest);
    const request = AccountSearch.getSearchRequest;
    // 検索入力オプション
    request.loginId && (this.inputOptions.loginId = request.loginId);
    request.name && (this.inputOptions.name = request.name);
    this.inputOptions.status = request.status || 0;
    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await AccountSearch.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async actionClickCallback(item: ListItem) {
    await this.$router.push({
      name: "authority-setting",
      params: { id: item.id.toString() }
    });
  }

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async editClickCallback(item: ListItem) {
    await this.$router.push({
      name: "login-members-edit",
      params: { id: item.id.toString() }
    });
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  deleteClickCallback(item: ListItem) {
    this.showDialog = true;
    this.deletingId = item.id;
    this.deletingTitle = item.name;
  }

  /**
   * ダイアログの削除のコールバック
   */
  async doDeleteOnDialog() {
    await Flash.clear();
    this.showDialog = false;
    if (this.deletingId <= 0) {
      return;
    }

    this.isLoading = true;
    await AccountModify.deleteOne({
      id: this.deletingId
    } as DeleteRequest);
    if (AccountModify.isSuccess) {
      await Flash.setSuccessNow({
        message: "店舗アカウントを削除しました。",
        consumePath: ""
      } as SuccessAlert);
      await this.search();
    } else {
      await Flash.setErrorNow({
        message: AccountModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * 検索処理
   */
  async search() {
    this.isLoading = true;
    let request = this.createRequest();
    await AccountSearch.search(request);
    if (!AccountSearch.isSuccess) {
      await Flash.setErrorNow({
        message: AccountSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  goToNew() {
    this.$router.push({ name: "login-members-new" }).then();
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {
    const request: SearchRequest = this.tableOptions as SearchRequest;
    if (this.inputOptions.loginId) {
      request.loginId = this.inputOptions.loginId;
    } else {
      delete request.loginId;
    }

    if (this.inputOptions.name) {
      request.name = this.inputOptions.name;
    } else {
      delete request.name;
    }

    if (this.inputOptions.status) {
      request.status = 1;
    } else {
      request.status = 0;
    }
    return request;
  }
}
